import {
  type AnnouncementBannerContainerData,
  type AnnouncementBannerDetailsData,
} from '@/components/CMS/types'
import styled, { css } from 'styled-components'
import { useUtmUrl } from '@/hooks/useUtmUrl'
import { routes } from '@shared/constants/routes'
import { Body } from '@shipt/design-system-typography'
import { Button, IconButton } from '@shipt/design-system-buttons'
import { useEffect, useState } from 'react'
import { classNames } from '@/utils/classNames'
import { staticColors } from '@/theme/staticColors'
import { screenSizes } from '@shipt/design-system-themes'
import { NegativeIcon, DuplicateIcon } from '@shipt/design-system-icons'
import { CMSErrorHandler } from '@/components/CMS/CMSErrorHandler'
import { useCMSTrackingEvents } from '@/components/CMS/CMSContext'
import { useCopyToClipboard } from 'react-use'
import { getCMSWrapperProps } from '@/components/CMS/utils/getCMSWrapperProps'
import { ButtonLink } from '@/elements/Link/Buttons'

/* The announcement banner is made up from two CMS content types:
Announcement Banner Container & Announcement Banner Details.

The Announcement Banner Container has a single reference field
that accepts a single Announcement Banner Details instance.
The Announcement Banner Details is the content type that holds
all of the data for the banner.

This strategy was used so that it would allow business users
to easily reuse & schedule promo banner changes in the CMS.
*/
export const AnnouncementBannerContainer = ({
  content_type_id,
  id,
  data: { announcement_banner },
}: AnnouncementBannerContainerData) => {
  const bannerProps = announcement_banner[0]

  try {
    return (
      <div {...getCMSWrapperProps({ content_type_id, id })}>
        <AnnouncementBannerDetails {...bannerProps} />
      </div>
    )
  } catch (error) {
    return (
      <CMSErrorHandler error={error} contentTypeId={content_type_id} id={id} />
    )
  }
}

export const AnnouncementBannerDetails = ({
  content_type_id,
  id,
  data: { heading, promo_code, cta_text, color_treatment },
}: AnnouncementBannerDetailsData) => {
  const { trackCMSElementViewed, trackCMSElementClicked } =
    useCMSTrackingEvents()
  const [promoClicked, setPromoClicked] = useState(false)
  const [closeButtonClicked, setCloseButtonClicked] = useState(false)
  const hideBanner = () => {
    setCloseButtonClicked(true)
  }
  const showPromoCode = Boolean(promo_code?.length)
  const decoratedUrl = useUtmUrl(cta_text?.url || routes.SIGN_UP.url)
  const [, copyToClipboard] = useCopyToClipboard()

  useEffect(() => {
    const trackingMessage = `${heading} ${
      showPromoCode ? promo_code : cta_text?.text
    }`.trim()
    trackCMSElementViewed({
      content: trackingMessage,
      type: 'banner',
      message_goal: 'shipt_order_promotion',
    })
  }, [heading, showPromoCode, promo_code, cta_text, trackCMSElementViewed])

  const handleBannerCTAClicked = () => {
    trackCMSElementClicked({ content: cta_text?.text, type: 'banner' })
  }

  const handleCopyPromoCode = () => {
    if (promo_code) {
      setPromoClicked(true)
      copyToClipboard(promo_code)
    }
  }

  return (
    <Banner
      {...getCMSWrapperProps({ content_type_id, id })}
      className={classNames(
        color_treatment,
        promoClicked && 'copied',
        closeButtonClicked && 'closed'
      )}
    >
      <Heading align="center" strong>
        {promoClicked ? 'Code copied! Please apply at checkout.' : heading}
      </Heading>
      {showPromoCode && (
        <CopyPromoCodeButton
          iconLeft={DuplicateIcon}
          onClick={handleCopyPromoCode}
          aria-label={`Copy Promo Code: ${promo_code}`}
          className={classNames(color_treatment, promoClicked && 'copied')}
          size="sm"
        >
          {promo_code}
        </CopyPromoCodeButton>
      )}
      {!showPromoCode && cta_text?.text && (
        <CTAButtonLink
          href={decoratedUrl}
          onClick={handleBannerCTAClicked}
          surface="inverse"
          size="sm"
          opensInNewTab={cta_text.open_in_new_tab}
        >
          {cta_text?.text}
        </CTAButtonLink>
      )}
      <CloseButtonWrapper>
        <CloseButton
          onClick={hideBanner}
          aria-label="Close Button"
          icon={NegativeIcon}
          className={classNames(color_treatment, promoClicked && 'copied')}
        />
      </CloseButtonWrapper>
    </Banner>
  )
}

const Banner = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  padding: 12px 35px;
  transition: all 0.25s ease-out;
  will-change: height, padding;

  @media ${screenSizes.tablet} {
    flex-direction: row;
    gap: 0.75rem;
  }

  &.primary {
    background-color: ${staticColors.plum};
    ${Body} {
      color: ${staticColors.white};
    }

    &.copied {
      background-color: #e2ddf8;
      ${Body} {
        color: #302638;
      }
    }
  }

  &.secondary {
    background-color: ${staticColors.green};
    ${Body} {
      color: ${staticColors.primary};
    }

    &.copied {
      background-color: #d5efb5;
    }
  }

  &.closed {
    overflow: hidden;
    height: 0;
    padding: 0;
  }
`

const Heading = styled(Body)`
  @media ${screenSizes.smDesktop} {
    font-size: 1.125rem;
  }
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: flex-start;

  @media ${screenSizes.tablet} {
    align-items: center;
  }
`

const CloseButton = styled(IconButton)`
  border: none;
  padding: 0;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;

  &,
  &:hover,
  &:focus {
    background-color: transparent;
  }

  @media ${screenSizes.tablet} {
    margin-top: 0;
    margin-right: 1.5rem;
  }

  &.secondary {
    color: ${staticColors.primary};
  }

  &.primary {
    color: ${staticColors.white};

    &.copied {
      color: ${staticColors.primary};
    }
  }
`

const buttonStyles = css`
  padding: 6px 14px;

  @media ${screenSizes.tablet} {
    padding: 10px 22px;
    font-size: 0.875rem;
  }
`

const CTAButtonLink = styled(ButtonLink)`
  ${buttonStyles};
`

const CopyPromoCodeButton = styled(Button)`
  ${buttonStyles};

  svg {
    width: 1rem;
    height: 1rem;
  }

  &.secondary {
    &,
    &:hover,
    &:focus {
      background-color: transparent;
      border-color: #19a35a;
      color: #024533;

      &.copied {
        background-color: #9bbc8e;
        border-color: transparent;
      }
    }
  }

  &.primary {
    &,
    &:hover,
    &:focus {
      background-color: transparent;
      border-color: #564e5d;
      color: #d1c8f4;

      &.copied {
        background-color: #c3b2f0;
        border-color: transparent;
        color: #381b5a;
      }
    }
  }
`
